/**
 * Schema
 * is defined in google sheet
 */
/* Example:
{
  author: '',
  description: '',
  title: '',
  genre: '',
  readYear: 2021, // year I discovered/read the book
  reviewLink: '', // internal link, if any
  links: {
    buy: '', // amazon, etc
    notes: '', // external notes, summaries
  }
}
*/

export const bookList = [
  {
    favorite: 'y',
    title: 'Big Magic: Creative Living Beyond Fear',
    author: 'Elizabeth Gilbert',
    description: '',
    genre: '',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Big-Magic-Creative-Living-Beyond/dp/0525590137',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Bittersweet: How Sorrow and Longing Make Us Whole',
    author: 'Susan Cain',
    description: '',
    genre: '',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Bittersweet-Sorrow-Longing-Make-Whole/dp/0593506057/ref=asc_df_0593506057?tag=bngsmtphsnus-20&linkCode=df0&hvadid=80882941400144&hvnetw=s&hvqmt=e&hvbmt=be&hvdev=c&hvlocint=&hvlocphy=&hvtargid=pla-4584482471864355&psc=1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Four Thousand Weeks: Time Management for Mortals',
    author: 'Oliver Burkeman',
    description: '',
    genre: '',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Four-Thousand-Weeks-Management-Mortals/dp/0374159122/ref=sr_1_1?keywords=4000+weeks+time+management+for+mortals&qid=1676223614&s=books&sprefix=4000%2Cstripbooks%2C99&sr=1-1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Radical Acceptance: Embracing Your Life With the Heart of a Buddha',
    author: 'Tara Brach',
    description: '',
    genre: '',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Radical-Acceptance-Embracing-Heart-Buddha/dp/0553380990',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Sapiens: A Brief History of Humankind',
    author: 'Yuval Noah Harari',
    description: '',
    genre: '',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Sapiens-Humankind-Yuval-Noah-Harari/dp/0062316095',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Start with Why: How Great Leaders Inspire Everyone to Take Action',
    author: 'Simon Sinek',
    description: '',
    genre: '',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Start-Why-Leaders-Inspire-Everyone/dp/1591842808/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1676224026&sr=1-1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'Accelerate: The Science of Lean Software and DevOps: Building and Scaling High Performing Technology Organizations',
    author: 'Nicole Forsgren, Jez Humble, Gene Kim.',
    description: '',
    genre: 'tech',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Accelerate-Software-Performing-Technology-Organizations-ebook/dp/B07B9F83WM',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Astrophysics for People in a Hurry',
    author: 'Neil deGrasse Tyson',
    description: '',
    genre: '',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Astrophysics-People-Hurry-deGrasse-Tyson/dp/0393609391/ref=sr_1_1?keywords=astrophysics+for+people+in+a+hurry&qid=1676223733&s=books&sprefix=Astrophysics+%2Cstripbooks%2C88&sr=1-1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Creative Quest',
    author: 'Questlove',
    description: '',
    genre: '',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Creative-Quest-Questlove-audiobook/dp/B07664YXST/ref=sr_1_1?keywords=creative+quest+by+questlove&qid=1676223700&s=books&sprefix=Creative+Quest%2Cstripbooks%2C97&sr=1-1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'Full Catastrophe Living: Using the Wisdom of Your Body and Mind to Face Stress, Pain, and Illness',
    author: 'Jon Kabat-Zinn',
    description:
      'Using examples from scientific studies and his Mindfulness-Based Stress Reduction program, the author motivates us to engage in formal and informal meditation practices. These include sitting, scanning our bodies, gentle yoga, walking, and examining the stressors in our lives through the lens of wholeness.',
    genre: '',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Full-Catastrophe-Living-Revised-Illness/dp/0345536932',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'Kill It with Fire: Manage Aging Computer Systems (and Future Proof Modern Ones)',
    author: 'Marianne Bellotti',
    description: '',
    genre: 'tech',
    readYear: 2022,
    buyLink:
      'https://www.amazon.com/Kill-Fire-Manage-Computer-Systems/dp/1718501188',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: '2 Second Lean: How to Grow People and Build a Fun Lean Culture',
    author: 'Paul Akers',
    description:
      'Lean principles applied to business and life. "Fix what bugs you." Free download.',
    genre: '',
    readYear: 2021,
    buyLink: 'https://paulakers.net/books/2-second-lean',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title:
      'Atomic Habits: An Easy & Proven Way to Build Good Habits & Break Bad Ones',
    author: 'James Clear',
    description:
      'Goals are useful for setting a direction, but systems are best for making progress.',
    genre: '',
    readYear: 2021,
    buyLink: '',
    reviewLink: '',
    authorLink: 'https://jamesclear.com/atomic-habits',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Feeling Good: The New Mood Therapy',
    author: 'David D. Burns',
    description:
      "The central premise is that if you think better (by examining and cognitive distortions with more accurate thoughts), you'll feel better. A seminal work of Cognitive Therapy.",
    genre: '',
    readYear: 2021,
    buyLink:
      'https://www.amazon.com/Feeling-Good-New-Mood-Therapy/dp/0380731762/ref=sr_1_3',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'I Will Teach You to be Rich',
    author: 'Ramit Sethi',
    description: '',
    genre: '',
    readYear: 2021,
    buyLink:
      'https://www.amazon.com/Will-Teach-You-Rich-Second/dp/1523505745/ref=sr_1_1?keywords=i+will+teach+you+to+be+rich+ramit+sethi&qid=1676224638&s=books&sprefix=I+will+%2Cstripbooks%2C94&sr=1-1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Originals: How Non-Conformists Move the World',
    author: 'Adam Grant',
    description:
      'Learn how to generate, recognize, voice, and champion original ideas. Better manage your emotional states, spark ideas in a team, and nurture originality.',
    genre: '',
    readYear: 2021,
    buyLink:
      'https://www.amazon.com/Originals-audiobook/dp/B01A7Q61LI/ref=sr_1_1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: "Quiet: The Power of Introverts in a World That Can't Stop Talking",
    author: 'Susan Cain',
    description:
      "Quiet is an antidote for society's fixation with sociability and extroversion. The book takes a tour through the wide-ranging research on introversion, demonstrating its underestimated upside – and how we need to adapt to take advantage of it.",
    genre: '',
    readYear: 2021,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title:
      'Think Like a Rocket Scientist: Simple Strategies You Can Use to Make Giant Leaps in Work and Life',
    author: 'Ozan Varol',
    description:
      'Through engaging stories to illustrate key concepts, Varol shares how to Launch, Accelerate, and Achieve. In Launch, we explore the topics of embracing uncertainty, reasoning from first principles, and thinking creatively. In Accelerate, we consider how we can improve our approach to problem solving. In Achieve, we learn how to respond to the flip-side outcomes of failure and success.',
    genre: '',
    readYear: 2021,
    buyLink: '',
    reviewLink: '',
    authorLink: 'https://ozanvarol.com/rocket',
    notesLink:
      'https://mentalpivot.com/book-notes-think-like-a-rocket-scientist-by-ozan-varol/',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Cues: Master the Secret Language of Charismatic Communication',
    author: 'Vanessa Van Edwards',
    description: '',
    genre: '',
    readYear: 2021,
    buyLink:
      'https://www.amazon.com/Cues-Master-Language-Charismatic-Communication/dp/0593332199/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1676224247&sr=1-1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      "Detox Your Thoughts: Quit Negative Self-Talk for Good and Discover the Life You've Always Wanted",
    author: 'Andrea Bonior',
    description: '',
    genre: '',
    readYear: 2021,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'Heart Breath Mind: Train Your Heart to Conquer Stress and Achieve Success',
    author: 'Dr. Leah Lagos',
    description:
      '10-week program to improve overall body functioning and reduce stress. This includes increasing heart rate variability (HRV), finding your resonant breathing frequency, and using exercises to reliably work through challenging emotions.',
    genre: '',
    readYear: 2021,
    buyLink: '',
    reviewLink: '',
    authorLink: 'https://drleahlagos.com/heart-breath-mind/',
    notesLink: 'https://maxfrenzel.com/books/heart-breath-mind',
    partiallyRead: 'y',
  },
  {
    favorite: '',
    title: "Hitchhiker's Guide to the Galaxy",
    author: 'Douglas Adams',
    description: '',
    genre: 'fiction',
    readYear: 2021,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: 'y',
  },
  {
    favorite: '',
    title:
      'How to Do the Work: Recognize Your Patterns, Heal from Your Past, and Create Your Self',
    author: 'Dr. Nicole LePera',
    description: '',
    genre: '',
    readYear: 2021,
    buyLink:
      'https://www.amazon.com/How-Do-Work-Recognize-Patterns/dp/006301209X',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Life Loves You: 7 Spiritual Practices to Heal Your Life',
    author: 'Robert Holden and Louise Hay',
    description: '',
    genre: '',
    readYear: 2021,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Multipliers: How the Best Leaders Make Everyone Smarter',
    author: 'Liz Wiseman',
    description: '',
    genre: '',
    readYear: 2021,
    buyLink:
      'https://www.amazon.com/Multipliers-Revised-Updated-Leaders-Everyone/dp/0062663070/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1676224335&sr=1-1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      "The Manager's Path: A Guide for Tech Leaders Navigating Growth and Change",
    author: 'Camille Fournier',
    description: '',
    genre: 'tech',
    readYear: 2021,
    buyLink:
      'https://www.amazon.com/Managers-Path-Leaders-Navigating-Growth/dp/1491973897',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'The Obstacle Is the Way: The Timeless Art of Turning Trials into Triumph',
    author: 'Ryan Holiday',
    description: '',
    genre: '',
    readYear: 2021,
    buyLink: 'https://www.amazon.com/s?k=1591846358&tag=sivers-20',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'The Phoenix Project: A Novel about IT, DevOps, and Helping Your Business Win',
    author: 'Gene Kim, Kevin Behr, George Spafford',
    description: '',
    genre: 'tech',
    readYear: 2021,
    buyLink:
      'https://www.amazon.com/The-Phoenix-Project-audiobook/dp/B00VATFAMI/ref=sr_1_1?keywords=phoenix+project&qid=1676224389&s=books&sprefix=phoenix+pro%2Cstripbooks%2C91&sr=1-1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Being Peace',
    author: 'Thich Nhat Hanh',
    description:
      'This book shows how our state of mind and body can make the world a peaceful place. We learn to transform the very situations that pressure and antagonize us into opportunities for practicing mindfulness.',
    genre: '',
    readYear: 2020,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Breath: The New Science of a Lost Art',
    author: 'James Nestor',
    description:
      'This book is filled with great tips, exercises, and anecdotes. Breathing exclusively through my nose has been a game-changer for sleep, singing, and more.',
    genre: '',
    readYear: 2020,
    buyLink: '',
    reviewLink: '',
    authorLink: 'https://www.mrjamesnestor.com/breath',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Essays',
    author: 'Dean Schlecht',
    description:
      'In addition to the essays, this psychotherapist provides meditation audio files that put your "active imagination" (a concept accredited to Carl Jung) to use.',
    genre: '',
    readYear: 2020,
    buyLink: '',
    reviewLink: '',
    authorLink: 'https://www.deanschlecht.com/',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title:
      "It's Not Always Depression: Working the Change Triangle to Listen to the Body, Discover Core Emotions, and Connect to Your Authentic Self",
    author: 'Hilary Jacobs Hendel',
    description:
      'The author teaches the "Change Triangle" methodology for processing emotions, calming, etc.',
    genre: '',
    readYear: 2020,
    buyLink: '',
    reviewLink: '',
    authorLink: 'https://www.hilaryjacobshendel.com/itsnotalwaysdepressionbook',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: "Man's Search for Meaning",
    author: 'Victor Frankl',
    description:
      'In Man’s Search for Meaning, Viktor Frankl tells his story of surviving a concentration camp and how this experience led to his theory on the importance of meaning in one’s life (Logotherapy). You can find meaning in work (by doing something significant), in love (by caring for another person), and in courage during difficult times.',
    genre: '',
    readYear: 2020,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: 'https://dansilvestre.com/mans-search-for-meaning-summary/',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: "The Surrender Experiment: My Journey into Life's Perfection",
    author: 'Michael Singer',
    description:
      'A much needed perspective shift. Why expecting reality to conform to your desires is a guaranteed path to disappointment. Instead, saying yes to opportunities presented to you and learning to be neutral (or even happy) about events you normally would be displeased with. Author is very much into meditation and being in touch with your higher mind / deeper self.',
    genre: '',
    readYear: 2020,
    buyLink: '',
    reviewLink: '',
    authorLink:
      'https://untetheredsoul.com/the-surrender-experiment-premise-excerpt',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'Adult Children of Emotionally Immature Parents: How to Heal from Distant, Rejecting, or Self-Involved Parents',
    author: 'Lindsay C. Gibson',
    description:
      '[id 31700](https://www.newharbinger.com/adult-children-emotionally-immature-parents/accessories)',
    genre: '',
    readYear: 2020,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Focusing',
    author: 'Eugene Gendlin',
    description:
      'In the same vein as It’s Not Always Depression. I really like the terminology the author uses.',
    genre: '',
    readYear: 2020,
    buyLink: 'https://www.amazon.com/Focusing-Eugene-T-Gendlin/dp/0553278339',
    reviewLink: '',
    authorLink: 'https://focusing.org/sixsteps',
    notesLink: '',
    partiallyRead: 'y',
  },
  {
    favorite: 'y',
    title: 'The Big Leap',
    author: 'Gay Hendricks',
    description:
      'Find your Zone of Genius. Conquer your self-imposed Upper Limits.',
    genre: '',
    readYear: 2019,
    buyLink:
      'https://www.amazon.com/Big-Leap-Conquer-Hidden-Level/dp/0061735361',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title:
      'Tools of Titans: The Tactics, Routines, and Habits of Billionaires, Icons, and World-Class Performers',
    author: 'Tim Ferriss',
    description:
      'is a massive compendium of everything Tim Ferriss has learned about health, wealth and wisdom from interviewing over 200 world-class performers on his podcast, The Tim Ferriss show. [All about Tim Ferriss](https://tim.blog/)',
    genre: '',
    readYear: 2019,
    buyLink:
      'https://www.amazon.com/Tools-Titans-Billionaires-World-Class-Performers/dp/1328683788',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Can’t Hurt Me',
    author: 'David Goggins',
    description: '',
    genre: '',
    readYear: 2019,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Daring Greatly',
    author: 'Brene Brown',
    description: '',
    genre: '',
    readYear: 2018,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      "A Mathematician's Lament: How School Cheats Us Out of Our Most Fascinating and Imaginative Art Form",
    author: 'Paul Lockhart',
    description:
      'This strongly worded opinion piece is organized into two parts. The first part, "Lamentation", criticizes the way mathematics is typically taught in American schools and argues for an aesthetic, intuitive, and problem-oriented approach to teaching. The second part, "Exultation", gives specific examples of how to teach mathematics as an art. [PDF](https://duckduckgo.com/?q=A+Mathematician%27s+Lament+by+Paul+Lockhart)',
    genre: '',
    readYear: 2017,
    buyLink:
      'https://www.amazon.com/Mathematicians-Lament-School-Fascinating-Imaginative/dp/1934137170',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'The Art of Learning: An Inner Journey to Optimal Performance',
    author: 'Josh Waitzkin',
    description: '',
    genre: '',
    readYear: 2017,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: "The Coder's Apprentice: Learning Programming with Python 3",
    author: 'Pieter Spronck',
    description: 'Nice way to learn Python and programming. Free download.',
    genre: 'tech',
    readYear: 2017,
    buyLink: 'http://spronck.net/pythonbook/',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'The Desire Map',
    author: 'Danielle LaPorte',
    description: '',
    genre: '',
    readYear: 2017,
    buyLink: '',
    reviewLink: '',
    authorLink: 'https://www.thedesiremap.com/',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'The Icarus Deception: How High Will You Fly?',
    author: 'Seth Godin',
    description: '',
    genre: '',
    readYear: 2017,
    buyLink:
      'https://www.amazon.com/The-Icarus-Deception-Seth-Godin-audiobook/dp/B00AECT2XK/ref=sr_1_1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      "The Story of Philosophy: The Lives and Opinions of the World's Greatest Philosophers",
    author: 'Will Durant',
    description: '',
    genre: 'philosophy',
    readYear: 2017,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'You Are a Badass: How to Stop Doubting Your Greatness and Start Living an Awesome Life',
    author: 'Jen Sincero',
    description: '',
    genre: '',
    readYear: 2017,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: "Drop Dead Healthy: One Man's Humble Quest for Bodily Perfection",
    author: 'A. J. Jacobs',
    description: '',
    genre: '',
    readYear: 2016,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      "The Toyota Way: 14 Management Principles from the World's Greatest Manufacturer",
    author: 'Jeffrey Liker',
    description:
      'Lean" strategy as demonstrated by the practices and success of the Toyota car company.',
    genre: '',
    readYear: 2016,
    buyLink:
      'https://www.amazon.com/Toyota-Way-Management-Principles-Manufacturer/dp/0071392319',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'You Can Heal Your Life',
    author: 'Louise Hay',
    description: '',
    genre: '',
    readYear: 2016,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: '7 Habits of Highly Effective People',
    author: 'Stephen Covey',
    description:
      'Habit 1: Be Proactive. Habit 2: Begin With the End in Mind. Habit 3: Put First Things First. Habit 4: Think Win-Win. Habit 5: Seek First to Understand, Then to Be Understood. Habit 6: Synergize. Habit 7: Sharpen the Saw.',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Habits-Highly-Effective-People/dp/0671708635',
    reviewLink: '',
    authorLink: 'https://www.franklincovey.com/the-7-habits/',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Antifragile: Things That Gain from Disorder',
    author: 'Nassim Taleb',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Antifragile-Things-That-Disorder-Incerto/dp/0812979680',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Eastern Body, Western Mind',
    author: 'Anodea Judith',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Eastern-Body-Western-Mind-Psychology/dp/1587612259',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Effortless Mastery',
    author: 'Kenny Werner',
    description: '',
    genre: 'music',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Effortless-Mastery-Liberating-Master-Musician/dp/156224003X',
    reviewLink: '',
    authorLink: 'https://kennywerner.com/effortless-mastery',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title:
      'Looking at Philosophy: The Unbearable Heaviness of Philosophy Made Lighter',
    author: 'Donald D. Palmer',
    description:
      'Through the use of humor and nearly 400 drawings, charts, and diagrams, serious philosophical topics come alive for the reader without compromising the importance of the subject matter.',
    genre: 'philosophy',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Looking-At-Philosophy-Unbearable-Heaviness/dp/0078119162',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title:
      'Mindfulness: An Eight-Week Plan for Finding Peace in a Frantic World',
    author: 'Williams and Penman',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Mindfulness-Eight-Week-Finding-Peace-Frantic/dp/1427217165',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Outliers: The Story of Success',
    author: 'Malcolm Gladwell',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Outliers-Story-Success-Malcolm-Gladwell/dp/0316017930/ref=sr_1_1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Solitude: A Return to the Self',
    author: 'Anthony Storr',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Solitude-Return-Self-Anthony-Storr/dp/0743280741',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'The Advancing Guitarist',
    author: 'Mick Goodrick',
    description: '',
    genre: 'music',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title:
      'The Back of the Napkin: Solving Problems and Selling Ideas with Pictures',
    author: 'Dan Roam',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.goodreads.com/book/show/2420301.The_Back_of_the_Napkin',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title:
      'The Breakout Principle: How to Activate the Natural Trigger That Maximizes Creativity, Athletic Performance, Productivity and Personal Well-Being',
    author: 'Herbert Benson and William Proctor',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Breakout-Principle-Creativity-Performance-Productivity/dp/0743223985',
    reviewLink: '',
    authorLink: '',
    notesLink: 'https://www.cbass.com/Breakout.htm',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title:
      'The Four Agreements: A Practical Guide to Personal Freedom (A Toltec Wisdom Book)',
    author: 'Don Miguel Ruiz',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'The Music Lesson',
    author: 'Victor Wooten',
    description: '',
    genre: 'music',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'The Tipping Point',
    author: 'Malcolm Gladwell',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'The War of Art',
    author: 'Steven Pressfield',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'The Way of Zen',
    author: 'Alan Watts',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: 'https://www.amazon.com/Way-Zen-Alan-W-Watts/dp/0375705104',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'Zen Guitar',
    author: 'Philip Toshio Sudo',
    description:
      'Lovely and very quotable. May transform how you approach guitar and music.',
    genre: 'music',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Zen-Guitar-Philip-Toshio-Sudo/dp/068483877X',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'All You Need to Know About the Music Business',
    author: 'Donald S. Passman',
    description: '',
    genre: 'music',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'As a Man Thinketh',
    author: 'James Allen',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Conversations with God',
    author: 'Neale Donald Walsch',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: 'y',
  },
  {
    favorite: '',
    title: 'Experiences in Visual Thinking',
    author: 'Robert McKim',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Experiences-Visual-Thinking-Robert-McKim/dp/B000IOFWHE',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Getting Things Done',
    author: 'David Allen',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Introducing Jung',
    author: 'Maggie Hyde, Michael McGuinness, Richard Appignanesi',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.betterworldbooks.com/product/detail/Introducing-Jung-9781840460629',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Music and the Mind',
    author: 'Anthony Storr',
    description: '',
    genre: 'music',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Narcissus and Goldmund',
    author: 'Hermann Hesse',
    description: '',
    genre: 'fiction',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Neuro-linguistic Programming for Dummies',
    author: 'Romilla Ready and Kate Burton',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: 'y',
  },
  {
    favorite: '',
    title:
      'Rational Mysticism: Spirituality Meets Science in the Search for Enlightenment',
    author: 'John Horgan',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Siddhartha',
    author: 'Hermann Hesse',
    description: '',
    genre: 'fiction',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Siddhartha-Novel-Hermann-Hesse/dp/0553208845',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'The 4 Hour Body: An Uncommon Guide to Rapid Fat Loss, Incredible Sex and Becoming Superhuman',
    author: 'Tim Ferriss',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: 'y',
  },
  {
    favorite: '',
    title:
      'The 4-Hour Workweek: Escape 9-5, Live Anywhere, and Join the New Rich',
    author: 'Tim Ferriss',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'The Meaning of Dreams',
    author: 'Calvin Hall',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'The Power of Habit',
    author: 'Charles Duhigg',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'The Power of Now: A Guide to Spiritual Enlightenment',
    author: 'Eckhart Tolle',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'The Prophet',
    author: 'Kahlil Gibran',
    description: '',
    genre: 'fiction',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'The Selfish Gene',
    author: 'Richard Dawkins',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: 'y',
  },
  {
    favorite: '',
    title:
      'The Tao of Physics: An Exploration of the Parallels between Modern Physics and Eastern Mysticism',
    author: 'Fritjof Capra',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      "The Year of Living Biblically: One Man's Humble Quest to Follow the Bible as Literally as Possible",
    author: 'A. J. Jacobs',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com/Year-Living-Biblically-Literally-Possible/dp/0743291484',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: "Think Better: An Innovator's Guide to Productive Thinking",
    author: 'Tim Hurson',
    description:
      'Favorite chapter is on Divergent Thinking [Tim Hurson talks about Productive Thinking | YouTube](https://www.youtube.com/watch?v=KYgEfriJsR8)',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'This is Your Brain on Music',
    author: 'Daniel J. Levitin',
    description: '',
    genre: 'music',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Thus Spoke Zarathustra',
    author: 'Friederich Nietzsche',
    description: '',
    genre: 'philosophy',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Why Beauty Is Truth: The History of Symmetry',
    author: 'Ian Stewart',
    description: '',
    genre: '',
    readYear: 2015,
    buyLink: '',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Zen Teaching of Emptiness',
    author: 'Gosung Shin',
    description:
      '[Cup of Tea Koan](http://www.ashidakim.com/zenkoans/1acupoftea.html) [What is Great Doubt in Zen? | Quora](https://www.quora.com/What-is-the-Great-Doubt-in-Zen)',
    genre: '',
    readYear: 2015,
    buyLink:
      'https://www.amazon.com.br/Teaching-Emptiness-English-Master-Gosung-ebook/dp/B0762V5BM4',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Guitar Technique',
    author: 'Hector Quine',
    description: '',
    genre: 'music',
    readYear: 2013,
    buyLink:
      'https://www.amazon.com/Guitar-Technique-Hector-Quine/dp/0193223236',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Musicophilia: Tales of Music and the Brain',
    author: 'Oliver Sacks',
    description: '',
    genre: '',
    readYear: 2013,
    buyLink:
      'https://www.amazon.com/Musicophilia-Tales-Music-Revised-Expanded/dp/1400033535',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Tunesmith: Inside the Art of Songwriting',
    author: 'Jimmy Webb',
    description: '',
    genre: 'music',
    readYear: 2013,
    buyLink:
      'https://www.amazon.com/Tunesmith-Inside-Songwriting-Jimmy-Webb/dp/0786884886',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title: 'Here and Now!: The Autobiography of Pat Martino',
    author: 'Pat Martino',
    description: '',
    genre: 'music',
    readYear: 2012,
    buyLink:
      'https://www.amazon.com/Here-Now-Autobiography-Pat-Martino/dp/1617130273/ref=sr_1_1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'Work the System: The Simple Mechanics of Making More and Working Less',
    author: 'Sam Carpenter',
    description:
      'Your job is not to be a fire-killer. Your job is to be a fire-prevention specialist. Stop expending energy in the constant repair of the bad results that have been produced by your unseen and therefore unmanaged systems. Instead, spend your days observing and then managing the systems that are creating the results.',
    genre: '',
    readYear: 2012,
    buyLink:
      'https://www.amazon.com/Work-System-Mechanics-Working-Revised/dp/160832253X',
    reviewLink: '',
    authorLink: 'https://www.workthesystem.com/',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: 'y',
    title: 'The Paradox of Choice: Why More Is Less',
    author: 'Barry Schwartz',
    description:
      'Everyday decisions have become increasingly complex due to the overwhelming abundance of choice with which we are presented. [TED Talk](https://www.ted.com/talks/barry_schwartz_the_paradox_of_choice)',
    genre: '',
    readYear: 2010,
    buyLink:
      'https://www.amazon.com/gp/product/0060005688/ref=dbs_a_def_rwt_hsch_vapi_thcv_p1_i1',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
  {
    favorite: '',
    title:
      'The Shaping Forces in Music: An Inquiry into the Nature of Harmony, Melody, Counterpoint and Form',
    author: 'Ernst Toch',
    description: '',
    genre: 'music',
    readYear: 2006,
    buyLink:
      'https://www.amazon.com/Shaping-Forces-Music-Counterpoint-Orchestral/dp/0486233464',
    reviewLink: '',
    authorLink: '',
    notesLink: '',
    partiallyRead: '',
  },
];
